/* eslint-disable @typescript-eslint/no-explicit-any */
import type { GatsbyBrowser } from 'gatsby';
import '@fontsource-variable/rubik';

import '../../styles/global.css';

const App: GatsbyBrowser['wrapRootElement'] = (props) => {
  const { element } = props;
  return element;
};

export default App;
